import Vue from 'vue';
import Vuetify, { Touch } from 'vuetify/lib';
// import VueTheMask from 'vue-the-mask';
// import DatetimePicker from 'vuetify-datetime-picker';
// import VueTour from 'vue-tour'

// Vue.use(VueTheMask);
// Vue.use(DatetimePicker);

// Vue tour
// require('vue-tour/dist/vue-tour.css')
// Vue.use(VueTour)

Vue.use(Vuetify, {
	directives: {
		Touch
	}
});
var getReadableForeColorHex = (hex) => {
	var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	var hexc = result
		? {
			r: parseInt(result[1], 16),
			g: parseInt(result[2], 16),
			b: parseInt(result[3], 16),
		}
		: null;
	return (hexc?.r + hexc?.b + hexc?.g) / 3 < 128 ? "#EEEEEE" : "#222222";
}
export default new Vuetify({
	theme: {
		options: {
			customProperties: true
		},
		themes: {
			light: {
				// Base
				base: '#393832',

				// Theme
				primary: '#472449',
				'primary-text': getReadableForeColorHex('#472449'),
				secondary: '#bfe5e8',
				'secondary-text': getReadableForeColorHex('#bfe5e8'),
				tertiary: '#efddd1',
				accent: '#FCE583',
				// 'webshop-primary': '#56A6AD',

				// State
				error: '#FF5252',
				info: '#2196F3',
				success: '#4CAF50',
				warning: '#FFC107',

				'cta-primary': '#EA412A',
				'cta-secondary': '#F9C6BF',
			}
		}
	},
	icons: {
		iconfont: 'mdi'
	}
});
