import axios from 'axios';

export default {
	/**
	 * Log out current user.
	 */
	async Logout() {
		const result = await axios.post(process.env.VUE_APP_FIXIT_CORE_API_URL + '/api/Authorization/logout', {});
		return !!(result.data && result.data.ResponseCode === 200);
	},
};
