import Vue from "vue";
import VueRouter from "vue-router";
import Profile from "../views/TheFixitProfile.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Profile,
    children: [
      { path: "mine-salonger", name: "Mine salonger", component: Profile },
      { path: "favorittansatte", name: "Favorittansatte", component: Profile },
      {
        path: "favorittbehandlinger",
        name: "Favorittbehandlinger",
        component: Profile,
      },
      {
        path: "favorittprodukter",
        name: "Favorittprodukter",
        component: Profile,
      },
      { path: "mine-gavekort", name: "Mine gavekort", component: Profile },
      {
        path: "mine-gavekort/:barcode/:usageArea",
        name: "Bruk gavekort",
        component: Profile,
      },
      {
        path: "betalingsmetoder",
        name: "Betalingsmetoder",
        component: Profile,
      },
      {
        path: "personvern-og-samtykker",
        name: "Personvern og samtykker",
        component: Profile,
      },
      { path: "profil", name: "Profil", component: Profile },

      { path: "ikke-mott", name: "Ikke møtt", component: Profile },
      {
        path: "ikke-mott/:siteId/:id",
        name: "Ikke møtt detaljer",
        component: Profile,
      },
      {
        path: "ikke-mott/:siteId/:id/betaling",
        name: "Valg av betalingsmetode",
        component: Profile,
      },
      {
        path: "ikke-mott/:siteId/:id/bekreftelse",
        name: "Betalingsbekreftelse",
        component: Profile,
      },

      {
        path: "sen-kansellering/:siteId/:id",
        name: "Sen kansellering detaljer",
        component: Profile,
      },
      {
        path: "sen-kansellering/:siteId/:id/betaling",
        name: "Valg av betalingsmetode for kansellering",
        component: Profile,
      },
      {
        path: "sen-kansellering/:siteId/:id/bekreftelse",
        name: "Betalingsbekreftelse for kansellering",
        component: Profile,
      },

      {
        path: "bestillinger",
        name: "Mine besøk og bestillinger",
        component: Profile,
      },
      {
        path: "/bestillinger/ordre/:orderId",
        name: "ProduktOrdredetaljer",
        component: Profile,
      },
      {
        path: "/bestillinger/:siteId/:orderId/:type",
        name: "Ordredetaljer",
        component: Profile,
      },

      { path: "/fordelsprogram", name: "Fordelsprogram", component: Profile },
      {
        path: "/fordelsprogram/:id",
        name: "Info fordelsprogram",
        component: Profile,
      },
      {
        path: "/fordelsprogram/:id/bli-med",
        name: "Bli med i fordelsprogram",
        component: Profile,
      },
      {
        path: "/fordelsprogram/:id/poenghistorikk",
        name: "Poenghistorikk",
        component: Profile,
      },
      {
        path: "/fordelsprogram/:id/vilkaar-for-bruk",
        name: "Vilkår for bruk",
        component: Profile,
      },
      {
        path: "/fordelsprogram/:id/poenghistorikk/:siteId/:orderId/:type",
        name: "PoengOrdredetaljer",
        component: Profile,
      },
    ],
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
