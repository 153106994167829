import { toCamelCase, toPascalCase } from './';

/**
 * Search and get the deep value in object by the given path. Paths are defined with a string
 * using `.` or `[...]` as delimiters. E.g `'obj.property'` or `'obj[property]'`
 * @author Kenneth Aamås (https://github.com/knekki)
 * @param {Object} obj - The source object for the search
 * @param {String} path - A string path that describes the value's location and its property name in the object.
 * @param {*} fallback - Fallback value if value is not found
 */
export function getObjectValueByPath(obj, path, fallback) {
	if (path === '' || (typeof path !== 'string' && typeof path !== 'number') || !isObject(obj)) return fallback;

	// Determine path
	path = String(path);
	path = path.replace(/\[(\w+)\]|\//g, '.$1'); // Convert indexes and/or paths to properties
	path = path.replace(/^\.|\.$/g, ''); // Strip a leading and/or trailing dot

	// Get the nested value by path
	const props = path.split('.');
	const lastIndex = props.length - 1;

	if (!~lastIndex) return obj === undefined ? fallback : obj;
	for (let i = 0; i < lastIndex; i++) {
		if (!isObject(obj) || obj[props[i]] === undefined) return fallback;
		obj = obj[props[i]];
	}

	return obj[props[lastIndex]] === undefined ? fallback : obj[props[lastIndex]];
}

/**
 * Checks if provided parameter is an object.
 * @author Kenneth Aamås (https://github.com/knekki)
 * @param {Object} obj - The source object to check
 */
export function isObject(obj) {
	return obj === Object(obj);
}

/**
 * Camel casing all property keys for provided object.
 * @param {Object} obj - The source object to convert all properties to camel case.
 * @returns New object with all property keys converted to camel case.
 */
export const toCamelCaseProperties = obj => {
	return obj ? Object.assign({},
		...Array.from(
			Object.entries(obj).map(([key, value]) => [toCamelCase(key), value]),
			([key, value]) => ({ [key]: value })
		)
	) : obj;
};

/**
 * Pascal casing all property keys for provided object.
 * @param {Object} obj - The source object to convert all properties to pascal case.
 * @returns New object with all property keys converted to pascal case.
 */
export const toPascalCaseProperties = obj => {
	return obj ? Object.assign({},
		...Array.from(
			Object.entries(obj).map(([key, value]) => [toPascalCase(key), value]),
			([key, value]) => ({ [key]: value })
		)
	) : obj;
};

export default {
	getObjectValueByPath,
	isObject,
	toCamelCaseProperties,
	toPascalCaseProperties
};
