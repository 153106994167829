import axios from 'axios';

export default {
	/**
     * Create payment session
     */
    async CreateSession(session) {
		return await axios.post(process.env.VUE_APP_FIXIT_PAY_API_URL + '/api/v1/session', session)
		  .then((res) => {
			if (res.data.ResponseCode === 200) {
			  return res.data.Dto;
			} else {
			  return false;
			}
		  })
		  .catch(function () {
			return false;
		  });
	  },
	/**
     * Create payment session
     */
    async CancelSession(sessionId) {
		return await axios.post(process.env.VUE_APP_FIXIT_PAY_API_URL + `/api/v1/session/${sessionId}/action/cancel`).then((res) => {
			if (res.data.ResponseCode === 200) {
				return true;
			} else {
				return false;
			}
		})
		.catch(function () {
		return false;
		});
	},
};
