<template>
  <v-app>
    <!-- Header -->
    <the-fixit-no-header
      style="z-index: initial"
      :header-props="header"
      :active-user="user"
      :use-axios="true"
      :new-release="
        (mainMenuHasPromotionItem && !headerNavigationHasPromotionItem) ||
        (mainMenuHasPromotionItem && $vuetify.breakpoint.smAndDown)
      "
      :support-membership="true"
      @setUser="setActiveUser"
      @updateUser="setActiveUser"
      @logout="logout()"
      @notify="setSnackbar"
      ref="fixitNoHeader"
    >
      <template v-slot:logo>
        <img
          @click="goHome()"
          src="https://umbraco.fixit.no/media/1348/fixit_logo_lilla.png"
          height="50%"
          style="object-fit: contain; cursor: pointer"
          loading="eager"
        />
      </template>
    </the-fixit-no-header>

    <!-- Main content -->
    <v-main>
      <the-fixit-profile
        style="min-height: 80vh"
        v-if="showProfile"
        :user="user"
        :layout="header.layout"
        :isProfile="header.isProfile"
        :use-axios="true"
        @notify="setSnackbar"
        @updateUser="(user) => setActiveUser(user, true)"
        @logout="logout()"
      />
    </v-main>
    <TheFixitNoSnackbar :snackbar="snackbar" @removeSnackbar="closeSnackbar" />
    <main-footer
      v-if="umbraco && showProfile && !isNoShowPayment"
      :umbraco="umbraco"
      class="mt-5"
    />
    <PaymentFooter
      :user="user"
      v-if="isNoShowPayment && !isPaymentConfirmationPage"
    ></PaymentFooter>
  </v-app>
</template>

<script>
import MainFooter from "@/components/MainFooter";
import MainMenu from "@/components/MainMenu";
import MainMenuIcon from "@/components/MainMenuIcon";
import TheFixitProfile from "./views/TheFixitProfile.vue";
import { waitFor } from "vue-wait";
import { User, Notification, Header } from "@fixit/fixit-no-header/src/models"; // @fixit/fixit-no-header
import { TheFixitNoHeader, TheFixitNoSnackbar } from "@fixit/fixit-no-header";
import { PaymentFooter } from "@fixit/fixit-no-header/src/components/Profile/components";
import { createOpacityVariables } from "@fixit/fixit-no-header/src/utils/opacity-colors";
import ProfileSvc from "@fixit/fixit-no-header/src/services/profile-svc";
import AppSvc from "@fixit/fixit-no-header/src/services/app-svc";
let service = null;
let appService = null;

export default {
  name: "App",
  components: {
    MainFooter,
    MainMenu,
    MainMenuIcon,
    TheFixitNoHeader,
    TheFixitNoSnackbar,
    TheFixitProfile,
    PaymentFooter,
  },
  data() {
    return {
      atTop: true,
      menuDialogOpen: false,
      overrideLoginOptions: null,
      showBadge: false,
      snackbarState: new Notification(),
      authRoutes: [
        "mypage",
        "webshopChainShoppingCartClickAndCollect",
        "webshopChainCheckoutClickAndCollect",
        "webshopChainCheckoutClickAndCollectOrderPreview",
        "webshopChainCheckoutCompletedClickAndCollect",
      ],
      umbraco: null,
    };
  },
  computed: {
    header() {
      let header = new Header({
        height: 70,
        elevation: 0,
        apiUrl: process.env.VUE_APP_FIXIT_CORE_API_URL,
        fixitNoUrl: process.env.VUE_APP_FIXIT_NO_URL,
        oauthUrl: process.env.VUE_APP_OAUTH_URL,
        items: this.umbraco
          ? this.umbraco.navigation.b2cMenuNavigation.map((item) => {
              return {
                Caption: item.caption,
                Id: item.id,
                Link: item.link,
                OpenInNewWindow: item.openInNewWindow,
                isInternal: item.isInternal,
                Visible: true,
                Type: item.type,
                Dialog: false,
                DialogVariable: false,
              };
            })
          : [],
        layout: "portal",
        isProfile: true,
        atTop: this.$root.atTop,
      });
      if (!this.$root.atTop) header.headerColors = ["#BFE5E8", "#C8E5DB"];
      return header;
    },
    user: {
      get() {
        return new User(this.$user);
      },
      set(user) {
        this.$user.assign(user);
      },
    },
    showProfile() {
      return this.user.onlineSluttKundeId != 0;
    },
    isNoShowPayment() {
      return (
        (this.$route.path.includes("/betaling") ||
          this.$route.path.includes("/bekreftelse")) &&
        (this.$route.path.includes("/ikke-mott") ||
          this.$route.path.includes("/sen-kansellering"))
      );
    },
    isPaymentConfirmationPage() {
      return this.$route.path.includes("/bekreftelse");
    },
    headerNavigationHasPromotionItem() {
      const promoteMenuItemText = this.umbraco
        ? this.umbraco.settings.promoteMenuItemContainingText
        : "";
      return this.umbraco
        ? this.umbraco.settings.promoteMainMenu &&
            this.umbraco.navigation.b2CHeaderNavigation.filter(
              (x) => x.caption.toLowerCase().indexOf(promoteMenuItemText) != -1
            ).length > 0
        : false;
    },
    mainMenuHasPromotionItem() {
      const promoteMenuItemText = this.umbraco
        ? this.umbraco.settings.promoteMenuItemContainingText
        : "";
      return this.umbraco
        ? this.umbraco.settings.promoteMainMenu &&
            this.umbraco.navigation.b2cMenuNavigation.filter(
              (x) => x.caption.toLowerCase().indexOf(promoteMenuItemText) != -1
            ).length > 0
        : false;
    },
    snackbar() {
      return this.snackbarState;
    },
  },
  methods: {
    async navigate(path) {
      await this.$router.push(`/${path}`).catch(() => {}); // add catch to handle NavigationDuplicated error exception in console
      this.menuDialogOpen = false;
    },
    goHome() {
      window.open(process.env.VUE_APP_FIXIT_NO_URL, "_self");
    },
    async setActiveUser(user, updatedUserInfo = false) {
      if (user.onlineSluttKundeId > 0) {
        this.setSnackbar({
          visible: true,
          duration: 3000,
          state: "info",
          message: updatedUserInfo
            ? "Din informasjon er oppdatert!"
            : "Du er logget inn!",
        });
        this.user = user;
        await this.$store.dispatch("fairpay/getFairPayAppointmentDetails");
      } else {
        if (this.user.onlineSluttKundeId > 0) {
          this.logout();
        }
      }

      if (
        this.$user.onlineSluttKundeId &&
        this.$route.query.customerId &&
        this.$route.query.siteId
      ) {
        var res = await appService.Logout(this.header.apiUrl);
        if (res) {
          this.user = res;
        }
      }
    },
    setSnackbar(snackbar) {
      this.snackbarState = new Notification(snackbar);
    },
    closeSnackbar(snackbar) {
      this.snackbarState = new Notification(snackbar);
    },
    async logout() {
      var res = await appService.Logout(this.header.apiUrl);
      if (res) {
        this.user = res;
        window.open(process.env.VUE_APP_FIXIT_NO_URL, "_self");
      }
    },
  },
  watch: {
    menuDialogOpen(value) {
      this.showBadge = !value;
    },
  },
  mounted() {
    var primary = getComputedStyle(document.documentElement).getPropertyValue(
      "--v-primary-base"
    );
    var secondary = getComputedStyle(document.documentElement).getPropertyValue(
      "--v-secondary-base"
    );
    createOpacityVariables(primary, secondary);
  },
  async created() {
    service = await ProfileSvc(this.$axios);
    appService = await AppSvc(this.$axios);
    this.umbraco = await service.GetUmbraco(
      process.env.VUE_APP_FIXIT_NO_BOOKING_URL
    );
    setTimeout(() => {
      this.showBadge = true;
    }, 1000);
  },
};
</script>

<style lang="scss">
@import "~@/styles/app";
</style>
