/**
 * Capitalize every word. Skips single letters e.g `"... i ..."`.
 * @param {String} str - The string to convert.
 */
export const toCapitalized = str => str.toLowerCase().split(' ').map((s) => s.length > 1 ? s.charAt(0).toUpperCase() + s.substring(1) : s).join(' ');

/**
 * Convert string to camel case.
 * @param {String} str - The string to convert.
 */
export const toCamelCase = str => str.substr(0, 1).toLowerCase() + str.substr(1);

/**
 * Convert string to kebab case.
 * @param {String} str - The string to convert.
 */
export const toKebabCase = str =>
	str
		.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
		.map(x => x.toLowerCase())
		.join('-');

/**
 * Convert string to pascal case.
 * @param {String} str - The string to convert.
 */
export const toPascalCase = str => str.substr(0, 1).toUpperCase() + str.substr(1);


/**
 * Replace accents characters in string
 * @param {String} str - The string to convert.
 */
export const strNormalize = str => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

export default {
	toCapitalized,
	toCamelCase,
	toKebabCase,
	toPascalCase
};
