import Vue from 'vue';
import Vuex from 'vuex';
import user  from './modules/user';
import payment from './modules/payment'
import fairpay from './modules/fairpay'

Vue.use(Vuex);

export default new Vuex.Store({
	// Modules
	modules: {
		user,
		payment,
		fairpay
	},

	// Global
	state: {},
	mutations: {},
	actions: {}
});
