import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./plugins/router";
import User from "./services/models/User";
import moment from "./plugins/moment";
import store from "./store";
import VueMasonry from "vue-masonry-css";

import "@/plugins";
Vue.config.productionTip = false;
Vue.prototype.$eventBus = new Vue();
window.CONFIG = { PORTAL_URL: process.env.VUE_APP_FIXIT_NO_URL };
window.CONFIG.user = new User(window.CONFIG.user);
Vue.prototype.$user = Vue.observable(window.CONFIG.user);
Vue.prototype.$moment = moment;
Vue.use(VueMasonry);
new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
