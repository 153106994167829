<template>
	<v-dialog v-model="dialog" fullscreen hide-overlay transition="fade-transition" :attach="attach" content-class="main-menu" eager>
		<template v-slot:activator="{ on }">
			<slot name="activator" v-bind:on="on"></slot>
		</template>

		<v-card class="primary main-menu__content d-flex align-center justify-end secondary--text" role="navigation" aria-label="Main" tile>
			<v-container fluid constrain--large d-flex :text-right="$vuetify.breakpoint.mdAndUp">
				<slot></slot>

				<slot name="main">
					<template v-for="(nav, index) in $umbraco.navigation.b2cMenuNavigation">
					<a :key="index" :href="nav.Link" v-if="$vuetify.breakpoint.mdAndDown || !alreadyVisibleInMainMenu(nav)">
						<template v-if="showMenuItemPromotion(nav.Caption) == true">
							<v-chip label text-color="primary" color="#FCE583">
								NYHET
							</v-chip>
						</template>
						{{ nav.Caption }}
					</a>
					</template>
				</slot>
			</v-container>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: 'main-menu',
	props: ['attach', 'value'],
	methods: {
		showMenuItemPromotion(caption) {
			// TODO: Refactor into Umbraco helper
			const displayMenuBadge = this.$umbraco.settings.promoteMainMenu;
			const promoteMenuItemText = this.$umbraco.settings.promoteMenuItemContainingText;

			return promoteMenuItemText && caption.toLowerCase().indexOf(promoteMenuItemText) != -1 && displayMenuBadge;
		},
		alreadyVisibleInMainMenu(menuItem){
			const exists = this.$umbraco.navigation.b2CHeaderNavigation.filter(item=> item.Link == menuItem.Link).length;

			return exists;
		}
	},
	computed: {
		dialog: {
			get() {
				return this.value;
			},

			set(value) {
				this.$emit('input', value);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.main-menu {
	& > &__content {
		transition-property: background-color, color;

		& > .container {
			flex-direction: column;
		}

		a {
			color: currentColor;
			font-size: 3rem;
			font-weight: 700;
			max-height: 1.5em;
			text-decoration: none;

			&:hover {
				color: var(--v-secondary-darken2);
			}

			@media #{map-get($display-breakpoints, 'sm-and-down')} {
				font-size: 2rem;
			}
		}
	}
}
</style>
