<template>
	<v-footer color="primary" class="footer secondary--text pt-8" height="auto">
		<v-container class="footer-container">
			<v-row>
				<v-col class="footer-navigation-col" cols="12" sm="6" md="3">
					<a href="/" class="text-left">
						<v-lazy>
							<img :src="umbraco.images.footerLogo" :alt="umbraco.images.footerLogo" width="100" style="object-fit: contain" class="mb-12" />
						</v-lazy>
					</a>
				</v-col>
				<v-col class="footer-navigation-col" cols="12" sm="6" md="3">
					<h3>For salonger</h3>
					<nav aria-label="Footer">
						<ul class="menu-navigation">
							<li class="mb-0" v-for="(navigation, index) in umbraco.navigation.b2cFooterNavigationSalons" :key="index">
								<a rel="noopener" :href="navigation.link" :target="navigation.openInNewWindow ? '_blank' : '_self'">{{ navigation.caption }}</a>
							</li>
						</ul>
					</nav>
				</v-col>
				<v-col class="footer-navigation-col" cols="12" sm="6" md="3">
					<h3>For kunder</h3>
					<nav aria-label="Footer">
						<ul class="menu-navigation">
							<li class="mb-0" v-for="(navigation, index) in umbraco.navigation.b2cFooterNavigationCustomers" :key="index">
								<a rel="noopener" :href="navigation.link" :target="navigation.openInNewWindow ? '_blank' : '_self'">{{ navigation.caption }}</a>
							</li>
						</ul>
					</nav>
				</v-col>
				<v-col class="footer-navigation-col" cols="12" sm="6" md="3">
					<h3>Følg oss på sosiale medier</h3>
					<section class="social-media-links mt-3 mb-4">
						<a
							v-for="(item, index) in umbraco.navigation.socialMediaNavigation"
							:key="index"
							:href="item.link[0].link"
							:target="item.link.openInNewWindow ? '_blank' : '_self'"
						>
							<img class="d-inline social-media-link mr-2" :src="getPropertyByAlias(item.iconSvg.properties, 'umbracoFile', 'content')" />
						</a>
					</section>
				</v-col>
			</v-row>
		</v-container>
	</v-footer>
</template>

<script>
export default {
	name: 'main-footer',
	methods: {
		getPropertyByAlias: function(properties, alias, property) {
			const result = properties.find(p => p.propertyTypeAlias == alias);
			if (!result) {
				return '';
			}
			return result[property];
		}
	},
	props: {
		umbraco: {
			required: true
		}
	}
};
</script>

<style lang="scss" scoped>
@import '~@/styles/app';
@import '~@/styles/variables';

.footer-container {
	max-width: var(--footer-width);
}

.social-media-links {
	.social-media-link {
		width: 44px;
	}
}
.footer-navigation-col {
	h3 {
		font-size: 1.3125rem;
		color: #ffffff;
		margin-bottom: 5px;
	}
	.menu-navigation {
		list-style: none;
		padding-left: 0;
		font-size: 1.125rem;

		@media #{map-get($display-breakpoints, 'xl-only')} {
			column-count: 1;
		}

		@media #{map-get($display-breakpoints, 'xs-only')} {
			font-size: 1.5rem;
		}
		li {
			padding-bottom: 3px;
			a {
				color: inherit;
				text-decoration: none;

				&:hover {
					color: var(--v-secondary-darken2);
				}
			}
		}
	}
}

.footer {
	min-height: 300px;
}
</style>