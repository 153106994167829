import { axiosRefreshTokenSetup } from "@fixit/utils";
import Vue from "vue";
import axios from "axios";

axiosRefreshTokenSetup(
  axios,
  "noredirect",
  `${process.env.VUE_APP_FIXIT_CORE_API_URL}/api/Authorization/refresh-token?origin=fixitno`
);
axios.defaults.headers.common["Application-Source"] = "FixitNo";
//axios.defaults.withCredentials = false;
//set global axios vue instance;
Vue.prototype.$axios = axios;
