import { getObjectValueByPath } from './';

/**
 * Order by function to be used with 'Array.sort' to simplify sorting with array of objects.
 * Specify the property name you want to order by. The `orderBy` function also compose a `thenBy` function
 * to be used if you want to chain the sorting. e.g: `orderBy().thenBy().thenBy()`.
 * @author Kenneth Aamås (https://github.com/knekki)
 * @param {String} property - Property to order by.
 * @param {Boolean} descending - Sorting direction.
 * @example
 * let items = [
 *  { name: 'Chocolate', category: 'snack' },
 *  { name: 'Apple', type: 'Red', category: 'fruit', meta: { supplier: 'ABC fruits' } },
 *  { name: 'Apple', type: 'Green', category: 'fruit', meta: { supplier: '123 fruits' } }
 * ];
 *
 * // Sort by 'category' first, then by 'name' and then by 'type':
 * items.sort(orderBy('category').thenBy('name').thenBy('type')); // => Apple (Green), Apple (Red), Chocolate
 *
 * // You can also sort in descending order by specifing the sorting direction in the second parameter in `orderBy` or `thenBy`:
 * items.sort(orderBy('category', true).thenBy('name', true).thenBy('type', true)); // => Chocolate, Apple (Red), Apple (Green)
 *
 * // If you want to sort by a property within an object, you can do a deep property sorting:
 * items.sort(orderBy('meta.supplier').thenBy('category')); // => Chocolate, Apple (Green), Apple (Red)
 */
export const orderBy = (function () {
	function orderBy(property, descending = false) {
		function compare(a, b) {
			const A = getObjectValueByPath(a, property);
			const B = getObjectValueByPath(b, property);

			if (!A) return -1;
			else if (!B) return 1;
			else if (descending) return B.localeCompare(A);
			else return A.localeCompare(B);
		}

		const context = typeof this == 'function' && !this.orderBy ? this : false;
		const sort = context ? function (a, b) {
			return context(a, b) || compare(a, b);
		} : compare;

		sort.thenBy = orderBy;
		return sort;
	}

	orderBy.orderBy = orderBy;
	return orderBy;
})();

export default {
	orderBy
};
