import UserService from '../../services/user';

export default {
	namespaced: true,
	state: {
	},
	getters: {
	},
	mutations: {
	},
	actions: {
		async logout() {
			return await UserService.Logout()
		},
	}
};
