import moment from '@/plugins/moment';
import { toCamelCaseProperties } from '@/utils/fn';

export default class User {
	constructor(args) {
		args = toCamelCaseProperties(args) || {};
		var id = args.membershipProfileId == '00000000-0000-0000-0000-000000000000' || !args.membershipProfileId ?  args.userId : args.membershipProfileId;
		var mobile = args.mobile == '' ? args.mobileNumber: args.mobile;
		this.address = args.address;
		this.birthDate = moment(args.birthDate || '');
		this.conditionsApproved = 'conditionsApproved' in args ? !!args.conditionsApproved : false; // Forcing conditions approved for new users because we dont want to show popup! (copied logic from old fixit.no code)
		this.email = args.email || '';
		this.extensionData = args.extensionData;
		this.firstName = args.firstName || '';
		this.fixitNoProfileDeleted = !!args.fixitNoProfileDeleted;
		this.lastName = args.lastName || '';
		this.membershipProfileId = id || '00000000-0000-0000-0000-000000000000' ;
		this.mobile = mobile ? mobile.replace(/ /g, '') : mobile || '';
		this.onlineSluttKundeId = args.onlineSluttKundeId || 0;
		this.password = args.password;
		this.postalArea = args.postalArea || '';
		this.postalCode = args.postalCode || '';
		this.profileCreatedTime = args.profileCreatedTime;
		this.profileUpdatedTime = args.profileUpdatedTime;
		this.receiveOffersAndNews = !!args.receiveOffersAndNews;
		this.sex = args.sex;
		this.phoneNoHome = args.phoneNoHome
		this.phoneNoWork = args.phoneNoWork
	}

	assign(user) {
		Object.assign(this, toCamelCaseProperties(user));
	}

	static get rules() {
		return {
			required: [v => !!v || 'Dette felter er påkrevd'],
			email: [v => !!v || 'E-post er påkrevd', v => /.+@.+\..+/.test(v) || 'E-post må være gyldig'],
			password: [v => !!v || 'Passord er påkrevd', v => (!!v && v.length >= 6) || 'Passordet må være minst 6 tegn'],
			sex: [v => v == 0 || !!v || 'Velg kjønn']
		};
	}
}
