<template>
	<div class="menu-icon" :class="{ 'menu-icon--active': isActive }">
		<span class="menu-icon__bar" :class="barClass"></span>
		<span class="menu-icon__bar" :class="barClass"></span>
		<span class="menu-icon__bar" :class="barClass"></span>
	</div>
</template>

<script>
export default {
	name: 'main-menu-icon',
	props: {
		barClass: String,
		isActive: Boolean
	}
};
</script>

<style lang="scss" scoped>
.menu-icon {
	$self: &;

	display: inline-flex;
	flex-direction: column;

	&__bar {
		background-color: var(--v-base-base);
		height: 3px;
		width: 24px;
		margin: 2px 0;
		transform: translateY(0) rotate(0);
		transition: background-color 250ms, transform 250ms;

		#{$self}--active & {
			&:first-child {
				transform: translateY(7px) rotate(-45deg);
			}

			&:nth-child(2) {
				visibility: hidden;
			}

			&:last-child {
				transform: translateY(-7px) rotate(45deg);
			}
		}
	}
}
</style>
